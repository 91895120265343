import { TransactionMovement, TransactionType } from "@/types";
import { getDefaultDateParams, STATEMENTS_FILTER_PARAMS } from "@/utils";
import { useSearchParams } from "react-router-dom";

export const useGetSearchParams = () => {
  const [searchParams] = useSearchParams();

  const { endDefaultDate, startDefaultDate } = getDefaultDateParams();

  const startDate =
    searchParams.get(STATEMENTS_FILTER_PARAMS.from) || startDefaultDate;
  const endDate =
    searchParams.get(STATEMENTS_FILTER_PARAMS.to) || endDefaultDate;
  const search = searchParams.get(STATEMENTS_FILTER_PARAMS.search) || "";
  const movement = searchParams.getAll(
    STATEMENTS_FILTER_PARAMS.movement,
  ) as TransactionMovement[];
  const category = searchParams.getAll(
    STATEMENTS_FILTER_PARAMS.category,
  ) as TransactionType[];
  const isWowStatements = searchParams.get("statements") === "WOW";

  return {
    startDate,
    endDate,
    search,
    movement,
    category,
    isWowStatements,
  };
};
