import { useReducer } from "react";
import { useGetSearchParams } from "@/features/statements/domain";
import {
  formatToInputDate,
  handleNormalizeDate,
  isValidDate,
  MAX_FILTER_RANGE,
  MAX_RANGE_WARNING,
  START_DATE_GREATER_THAN_END_DATE_ERROR,
  STATEMENTS_FILTER_CATEGORIES,
  STATEMENTS_FILTER_MOVEMENTS,
  STATEMENTS_FILTER_PARAMS,
  TODAY_INPUT,
} from "@/utils";
import { differenceInDays, format, isValid, parse, sub } from "date-fns";
import { useSearchParams } from "react-router-dom";

const MIN_MOVEMENT_LENGTH = 1;

type Movement = (typeof STATEMENTS_FILTER_MOVEMENTS)[number];

export type Category = (typeof STATEMENTS_FILTER_CATEGORIES)[number];

type StatementFilter = {
  startDate: string;
  endDate: string;
  movement: Movement[];
  categories: Category[];
  search: string;
  dateHelperMessage: string;
};

type StatementAction =
  | { type: "SET_FROM_DATE"; payload: string }
  | { type: "SET_TO_DATE"; payload: string }
  | { type: "SET_MOVEMENT"; payload: Movement }
  | { type: "SET_CATEGORIES"; payload: Category }
  | { type: "SET_RANGE"; payload: number }
  | { type: "SET_SEARCH"; payload: string }
  | {
      type: "SET_CLEAR_DATE";
      payload: keyof Pick<StatementFilter, "endDate" | "startDate">;
    }
  | { type: "SET_RESET" };

const RESET_VALUES: StatementFilter = {
  endDate: "",
  startDate: "",
  movement: ["IN", "OUT"],
  categories: [],
  search: "",
  dateHelperMessage: "",
};

const parseInputDate = (dateString: string) =>
  parse(dateString, "yyyy-MM-dd", new Date());

const handleDateInput = (dateValue: string) => {
  const date = handleNormalizeDate(dateValue);

  if (!isValidDate(date)) return "";

  return dateValue;
};

const handleRange = (range: number) => {
  const normalizedDate = handleNormalizeDate(TODAY_INPUT);
  const newStartDate = sub(normalizedDate, { days: range });

  return {
    endDate: TODAY_INPUT,
    startDate: formatToInputDate(newStartDate),
  };
};

const handleMovement = (stateMovement: Movement[], movement: Movement) => {
  if (
    !stateMovement.includes(movement) &&
    stateMovement.length === MIN_MOVEMENT_LENGTH
  ) {
    return RESET_VALUES.movement;
  }

  if (
    stateMovement.includes(movement) &&
    stateMovement.length === MIN_MOVEMENT_LENGTH
  ) {
    const filteredMovement = STATEMENTS_FILTER_MOVEMENTS.filter(
      (filterMovement) => filterMovement !== movement,
    );
    return filteredMovement;
  }
  const filteredMovement = STATEMENTS_FILTER_MOVEMENTS.filter(
    (filterMovement) => filterMovement !== movement,
  );

  return filteredMovement;
};

const handleCategories = (stateCategories: Category[], category: Category) => {
  // Se todas categorias estiverem selecionadas, mantém apenas a categoria clicada
  if (stateCategories.length === STATEMENTS_FILTER_CATEGORIES.length) {
    return [category];
  }

  // Toggle da categoria selecionada
  if (stateCategories.includes(category)) {
    return stateCategories.filter((c) => c !== category);
  } else {
    return [...stateCategories, category];
  }
};

const handleClearDate = (
  dateToClear: keyof Pick<StatementFilter, "endDate" | "startDate">,
) => {
  return {
    dateHelperMessage: "",
    [dateToClear]: "",
  };
};

const areDatesInMaxFilterRange = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const normalizedEndDate = handleNormalizeDate(endDate);
  const normalizedStartDate = handleNormalizeDate(startDate);

  if (!isValid(normalizedEndDate) || !isValid(normalizedStartDate)) return true;

  return (
    differenceInDays(normalizedEndDate, normalizedStartDate) <= MAX_FILTER_RANGE
  );
};

const isStartDateGreaterThanEndDate = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  if (!startDate || !endDate) return false;

  const formatedStartDate = parseInputDate(startDate);
  const formatedEndDate = parseInputDate(endDate);

  return formatedStartDate.getTime() > formatedEndDate.getTime();
};

const handleDateInputLogic = ({
  datePayload,
  input,
  state,
}: {
  state: StatementFilter;
  datePayload: string;
  input: keyof Pick<StatementFilter, "endDate" | "startDate">;
}): StatementFilter => {
  const normalizedDate = handleDateInput(datePayload);

  if (!normalizedDate) return state;

  const myState: StatementFilter = {
    ...state,
    [input]: normalizedDate,
  };

  const areDatesInRange = areDatesInMaxFilterRange({
    startDate: myState.startDate,
    endDate: myState.endDate,
  });

  if (!areDatesInRange)
    return {
      ...myState,
      dateHelperMessage: MAX_RANGE_WARNING,
    };

  const isStartDateGreater = isStartDateGreaterThanEndDate({
    startDate: myState.startDate,
    endDate: myState.endDate,
  });

  if (isStartDateGreater)
    return {
      ...myState,
      dateHelperMessage: START_DATE_GREATER_THAN_END_DATE_ERROR,
    };

  return {
    ...myState,
    dateHelperMessage: "",
  };
};

export const useStatementFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    startDate: startDateParam,
    endDate: endDateParam,
    movement: movementParam,
    category: categoryParam,
    search: searchParam,
  } = useGetSearchParams();

  const initialState: StatementFilter = {
    endDate: endDateParam,
    startDate: startDateParam,
    movement: movementParam.length
      ? (movementParam as Movement[])
      : RESET_VALUES.movement,
    categories: categoryParam as Category[],
    search: searchParam,
    dateHelperMessage: "",
  };

  const reducer = (state: StatementFilter, action: StatementAction) => {
    switch (action.type) {
      case "SET_FROM_DATE":
        return handleDateInputLogic({
          datePayload: action.payload,
          input: "startDate",
          state,
        });
      case "SET_TO_DATE":
        return handleDateInputLogic({
          datePayload: action.payload,
          input: "endDate",
          state,
        });

      case "SET_CATEGORIES":
        return {
          ...state,
          categories: handleCategories(state.categories, action.payload),
        };
      case "SET_MOVEMENT":
        return {
          ...state,
          movement: handleMovement(state.movement, action.payload),
        };
      case "SET_RANGE":
        return {
          ...state,
          ...handleRange(action.payload),
        };
      case "SET_SEARCH":
        return {
          ...state,
          search: action.payload,
        };
      case "SET_CLEAR_DATE":
        return {
          ...state,
          ...handleClearDate(action.payload),
        };
      case "SET_RESET":
        return initialState;
      default:
        return initialState;
    }
  };

  const [filterState, dispatch] = useReducer(reducer, initialState);

  const getSelectedRange = () => {
    const normalizedFrom = handleNormalizeDate(filterState.startDate);
    const normalizedTo = handleNormalizeDate(filterState.endDate);

    return differenceInDays(normalizedTo, normalizedFrom);
  };

  const resetFilters = () => {
    dispatch({ type: "SET_RESET" });
    setSearchParams();
  };

  const shouldBeAbleToFilter =
    !!filterState.endDate &&
    filterState.startDate &&
    !filterState.dateHelperMessage;

  const handleFilter = () => {
    if (!shouldBeAbleToFilter) return;

    if (filterState.movement.length === MIN_MOVEMENT_LENGTH)
      searchParams.set(
        STATEMENTS_FILTER_PARAMS.movement,
        filterState.movement[0],
      );
    else searchParams.delete(STATEMENTS_FILTER_PARAMS.movement);

    if (filterState.categories.length) {
      searchParams.delete(STATEMENTS_FILTER_PARAMS.category);
      filterState.categories.forEach((category) =>
        searchParams.append(STATEMENTS_FILTER_PARAMS.category, category),
      );
    }

    if (filterState.startDate && filterState.endDate) {
      searchParams.set(STATEMENTS_FILTER_PARAMS.from, filterState.startDate);
      searchParams.set(STATEMENTS_FILTER_PARAMS.to, filterState.endDate);
    }

    if (filterState.search)
      searchParams.set(STATEMENTS_FILTER_PARAMS.search, filterState.search);

    setSearchParams(searchParams);
  };

  const formateDateForUi = (dateString: string) => {
    if (!dateString) return format(new Date(), "dd/MM/yyyy");
    const date = parseInputDate(dateString);

    return format(date, "dd/MM/yyyy");
  };

  return {
    filterState,
    dispatch,
    getSelectedRange,
    resetFilters,
    handleFilter,
    shouldBeAbleToFilter,
    parseInputDate,
    formateDateForUi,
  };
};
