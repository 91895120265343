import { ElementRef, Suspense, useRef } from "react";
import { useMediaQuery } from "@/utils";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { Button, Card, Dialog, Sheet, toast } from "@hyperlocal/vital2";
import { ErrorBoundary } from "react-error-boundary";
import { twMerge } from "tailwind-merge";
import { useExportStatements } from "./use-export-statements";

export type FileType = "pdf" | "csv" | "ofx";

interface FileTypeOptions {
  label: string;
  icon: IconProps["name"];
  type: FileType;
}

const files: FileTypeOptions[] = [
  { label: "Excel", icon: "GeralDocApkDocument", type: "csv" },
  { label: "PDF", icon: "GeralDocPdf", type: "pdf" },
  { label: "OFX", icon: "GeralDocApkDocument", type: "ofx" },
];

const ExportStatements = () => {
  const isMobile = useMediaQuery("mobile");
  const dialogRef = useRef<ElementRef<typeof Dialog.Close>>(null);

  const { exportData, fileType, getDates, setFileType, getExportData } =
    useExportStatements();

  const { endDate, startDate } = getDates();

  const onFileClick = (file) => {
    setFileType(file.type);
    exportData(file.type);
  };

  const handleOnTryToExport = (e) => {
    const allowedToExport = !!startDate || !!endDate;
    if (allowedToExport) return;

    e.preventDefault();

    toast({
      variant: "inform",
      title: "Período inválido",
      description: "Por favor, selecione um período para exportar o relatório.",
    });
  };

  const renderFileOptions = () =>
    files.map((file) => {
      const isDisabled = getExportData.loading;
      const isLoading = isDisabled && file.type === fileType;
      return (
        <button
          key={file.type}
          onClick={() => !isDisabled && onFileClick(file)}
          disabled={isDisabled}
          className={twMerge(isDisabled && "cursor-not-allowed")}
        >
          <Card
            className={twMerge(
              "flex w-full cursor-pointer content-between items-center text-neutral-black",
            )}
          >
            <div className="flex w-full gap-inset-x2s">
              <Icon
                name={file.icon}
                className="size-inset-lg"
                fill="currentColor"
              />
              <span>{file.label}</span>
            </div>
            {isLoading ? (
              <Icon
                name="ArrowRefreshLine"
                fill="currentColor"
                className="size-inset-lg animate-spin"
              />
            ) : (
              <Icon
                name="ArrowNoLineRigh"
                fill="currentColor"
                className="size-inset-lg"
              />
            )}
          </Card>
        </button>
      );
    });

  return (
    <>
      {!isMobile && (
        <div>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button.Root
                onClick={handleOnTryToExport}
                className="button-export-wildcard mb-4 flex !h-8 w-fit items-center space-x-2 border border-primary-main px-3 py-2 text-xs leading-none text-primary-main"
                variant="secondary"
              >
                <Button.Slot name="GeralShareExport" className="size-[24px]" />
                Exportar
              </Button.Root>
            </Dialog.Trigger>
            <Dialog.Content className="max-w-[370px]">
              <Dialog.Header>
                <Dialog.Title>Exportar</Dialog.Title>
                <Dialog.Close ref={dialogRef}></Dialog.Close>
              </Dialog.Header>
              Por favor, selecione uma das opções sobre esta compra:
              <div className="flex flex-col gap-2">{renderFileOptions()}</div>
            </Dialog.Content>
          </Dialog.Root>
        </div>
      )}
      {isMobile && (
        <div>
          <Sheet.Root>
            <Sheet.Trigger asChild>
              <Button.Root
                onClick={handleOnTryToExport}
                className="!h-fit shrink-0 !p-0 !px-2 !py-2 text-xs leading-none"
                variant="link"
              >
                <Button.Slot name="GeralShareExport" className="size-6" />
                Exportar
              </Button.Root>
            </Sheet.Trigger>
            <Sheet.Content side="bottom">
              <Sheet.Header>
                <Sheet.Title>Exportar</Sheet.Title>
                <Dialog.Close ref={dialogRef}></Dialog.Close>
              </Sheet.Header>
              Por favor, selecione uma das opções sobre esta compra:
              <div className="flex flex-col gap-2">{renderFileOptions()}</div>
            </Sheet.Content>
          </Sheet.Root>
        </div>
      )}
    </>
  );
};

export const ExportStatementsAction = () => (
  <ErrorBoundary FallbackComponent={() => <div />}>
    <Suspense>
      <ExportStatements />
    </Suspense>
  </ErrorBoundary>
);
