import { Suspense } from "react";
import { ErrorFeedback, LoadingTransaction } from "@/components";
import { WowStatements } from "@/features/statements/components/wow-statements";
import { WowTransactionList } from "@/features/statements/components/wow-transaction-list";
import { useGetSearchParams } from "@/features/statements/domain";
import { routes, useMediaQuery } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { Card, TextField } from "@hyperlocal/vital2";
import { Link, useSearchParams } from "react-router-dom";
import {
  ExportStatementsAction,
  FilterSheet,
  TransactionList,
} from "../../components";
import { useFilterHelper } from "../filter/helper";
import * as Svg from "./svgs";

const TransactionListComponent = () => {
  const [searchParams] = useSearchParams();

  return (
    <ErrorFeedback>
      <Suspense
        fallback={
          <div className="h-full px-6">
            <LoadingTransaction />
          </div>
        }
      >
        {searchParams.get("statements") === "WOW" ? (
          <WowTransactionList />
        ) : (
          <TransactionList />
        )}
      </Suspense>
    </ErrorFeedback>
  );
};

export const Transactions = () => {
  const { handleSearch } = useFilterHelper();

  const [searchParams] = useSearchParams();
  const { search } = useGetSearchParams();
  const isMobile = useMediaQuery("mobile");
  const isWowStatements = searchParams.get("statements") === "WOW";
  return (
    <div className="flex h-full flex-col overflow-hidden rounded-sm bg-neutral-white pt-inset-lg">
      <div className="px-inset-lg">
        <FilterSheet />

        {!isMobile && (
          <div className="flex gap-2">
            <WowStatements />
            {!isWowStatements && <ExportStatementsAction />}
          </div>
        )}

        {!isWowStatements && (
          <Link to={routes.commitments}>
            <Card className="mb-4 flex w-full items-center whitespace-nowrap border-primary-main font-base text-[clamp(12px,3cqw,1rem)] font-bold text-primary-main">
              <Icon
                name="GeralMoneyAutopay"
                className="mr-2 size-6 fill-current"
              />
              Acompanhe os compromissos
              <Svg.ArrowRight className="ml-auto" />
            </Card>
          </Link>
        )}
        <TextField.Root className="mb-4 desktop:!hidden">
          <TextField.Input
            onChange={({ target }) => handleSearch(target.value)}
            value={search}
            placeholder="Buscar..."
          />
          <TextField.Slot name="SearchNormal" />
        </TextField.Root>
      </div>

      <TransactionListComponent />

      {isMobile && (
        <div className="grid grid-cols-2 place-items-center py-2">
          <WowStatements />
          {!isWowStatements && <ExportStatementsAction />}
        </div>
      )}
    </div>
  );
};
