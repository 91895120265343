import { useEffect } from "react";
import { LoadingTransaction, ScrollArea } from "@/components";
import { usePaymentsPending } from "@/features/commitments/services";
import { TransactionItemV2 } from "@/features/statements/components/transaction-item";
import { useGetSearchParams } from "@/features/statements/domain";
import {
  getDateTitle,
  isSameDate,
  TODAY,
  useNewStatementPermission,
} from "@/utils";
import { formatCurrency } from "@/utils/format";
import { parse } from "date-fns";
import { useInView } from "react-intersection-observer";
import {
  useInfiniteStatements,
  useInfiniteStatementsV2,
} from "../../services/hooks";
import { TransactionItem } from "../transaction-item";

export const TransactionListV1 = () => {
  const { ref, inView } = useInView();

  const { startDate, endDate, movement, category } = useGetSearchParams();

  const { data, fetchNextPage, hasNextPage } = useInfiniteStatements({
    movement: movement[0],
    startDate,
    endDate,
    type: category,
  });

  usePaymentsPending({ enabled: !!data });

  const isListEmpty = !data || !Object.keys(data?.pages).length;

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isListEmpty)
    return (
      <span className="flex h-full w-full items-center justify-center">
        Nenhuma transação encontrada
      </span>
    );

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <ScrollArea className="h-full px-inset-lg pb-inset-lg mobile:pb-0">
        {Object.entries(data.pages).map(([key, items]) => {
          const date = parse(key, "dd/MM/yy", new Date());
          const title = getDateTitle(date);

          const isToday = isSameDate(TODAY, date);
          return (
            <div key={key}>
              <h5 className="font-base text-sm/[30px] font-bold text-neutral-darkest">
                {isToday && "Hoje, "}
                {title}
              </h5>

              <ul className="my-4 flex flex-col gap-2">
                {items.map((item, index) => (
                  <TransactionItem key={`${item.id}-${index}`} item={item} />
                ))}
              </ul>
            </div>
          );
        })}

        {hasNextPage ? (
          <LoadingTransaction ref={ref} />
        ) : (
          <span className="flex w-full justify-center text-center">
            Não há mais nada por aqui...
            <br /> Para consultar datas anteriores use o filtro.
          </span>
        )}
      </ScrollArea>
    </div>
  );
};

export const TransactionListV2 = () => {
  const { ref, inView } = useInView();

  const { data, fetchNextPage, hasNextPage } = useInfiniteStatementsV2();

  const isListEmpty = !data || !Object.keys(data?.pages).length;

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isListEmpty)
    return (
      <span className="flex h-full w-full items-center justify-center">
        Nenhuma transação encontrada
      </span>
    );

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <ScrollArea className="h-full w-full px-6 pb-6 mobile:pb-0">
        {Object.entries(data.pages).map(([key, { balance, items }]) => {
          const date = parse(key, "dd/MM/yy", new Date());
          const title = getDateTitle(date);

          const isToday = isSameDate(TODAY, date);
          return (
            <div key={key} className="w-auto">
              <div className="flex items-center justify-between">
                <h5 className="font-base text-sm/[30px] font-bold text-neutral-darkest">
                  {isToday && "Hoje, "}
                  {title}
                </h5>
                {!isToday && !!balance && (
                  <span className="font-base text-xs/tight text-neutral-dark">
                    Saldo do dia: {formatCurrency(balance)}
                  </span>
                )}
              </div>

              <ul className="my-4 flex flex-col gap-2">
                {items.map((item, index) => (
                  <TransactionItemV2 key={index} item={item} />
                ))}
              </ul>
            </div>
          );
        })}

        {hasNextPage ? (
          <LoadingTransaction ref={ref} />
        ) : (
          <span className="flex w-full justify-center text-center">
            Não há mais nada por aqui...
            <br /> Para consultar datas anteriores use o filtro.
          </span>
        )}
      </ScrollArea>
    </div>
  );
};

export const TransactionList = () => {
  const shouldRenderNewStatement = useNewStatementPermission();

  return shouldRenderNewStatement ? (
    <TransactionListV2 />
  ) : (
    <TransactionListV1 />
  );
};
