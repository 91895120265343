import { useAccountStore } from "@/utils";
import { Button } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const WowStatements = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { fok } = useAccountStore();
  const isActive = searchParams.get("statements") === "WOW";
  const isDisabled = !fok;

  return (
    <Button.Root
      className={twMerge(
        "button-export-wildcard mb-4 flex !h-8 w-fit items-center space-x-2 border border-primary-main px-3 py-2 text-xs leading-none text-primary-main mobile:mb-0",

        isActive && "bg-primary-main text-white hover:text-white",
      )}
      variant="secondary"
      disabled={isDisabled}
      onClick={() =>
        setSearchParams((params) => {
          params.delete("to");
          params.delete("from");

          isActive
            ? params.delete("statements")
            : params.set("statements", "WOW");

          return params;
        })
      }
    >
      Extrato Histórico
    </Button.Root>
  );
};
