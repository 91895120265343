import { TransactionItem, TransactionItemTransformed } from "@/types";
import {
  CATEGORIES_DICTIONARY,
  MOVEMENT_DICTIONARY,
  RAW_TYPE_DICTIONARY,
} from "@/utils/constants";
import { formatCurrency } from "@/utils/format";
import { addMinutes, format } from "date-fns";

export const transformStatementsResponse = (
  transactions: TransactionItem[],
) => {
  return transactions.reduce<TransactionItemTransformed[]>(
    (acc, transaction) => {
      if (
        !transaction.Type ||
        ![
          "Payment",
          "cartao",
          "P2P",
          "Pix",
          "TED",

          // WOW types
          "p2p_rec",
          "ted_spb_envio",
          "doc_ted_fee",
          "ted_spb_envio_estorno",
          "pix_debito",
          "pix_estorno_debito",
        ].includes(transaction.Type)
      )
        return acc;

      acc.push({
        id: transaction.Id,
        createdAt: transaction.CreatedAt,
        type: CATEGORIES_DICTIONARY[transaction.Type] || "Transação",
        rawType: RAW_TYPE_DICTIONARY[transaction.Type] || "Transação",
        description: transaction.Description || "",
        participant: transaction.Participant || "",
        movement: MOVEMENT_DICTIONARY[transaction.Movement],
        value: transaction.Value,
      });

      return acc;
    },
    [],
  );
};

export type CSVExportData = {
  date: string;
  type: string;
  description: string;
  credit: number | null;
  debit: number | null;
};

export const transformCSVStatementsResponse = (
  transactions: TransactionItem[],
) => {
  return transactions.reduce<CSVExportData[]>((acc, transaction) => {
    if (
      !transaction.Type ||
      !["Payment", "cartao", "P2P", "Pix", "TED"].includes(transaction.Type)
    )
      return acc;

    acc.push({
      date: formatDateHour(transaction.CreatedAt),
      type: CATEGORIES_DICTIONARY[transaction.Type],
      description: transaction.Description || "",
      credit: transaction.Movement === "OUT" ? null : transaction.Value,
      debit: transaction.Movement === "OUT" ? transaction.Value * -1 : null,
    });
    return acc;
  }, []);
};

export type PDFExportData = Omit<
  TransactionItemTransformed,
  "rawType" | "value"
> & {
  value: string;
};

export const formatDateHour = (dateString: string) => {
  const date = new Date(dateString);
  const createdAt = addMinutes(date, date.getTimezoneOffset());

  return format(createdAt, "dd/MM/yy - HH:mm");
};

export const transformPDFStatementsResponse = (
  transactions: TransactionItem[],
) => {
  return transactions.reduce<PDFExportData[]>((acc, transaction) => {
    if (
      !transaction.Type ||
      !["Payment", "cartao", "P2P", "Pix", "TED"].includes(transaction.Type)
    )
      return acc;

    acc.push({
      id: transaction.Id,
      createdAt: formatDateHour(transaction.CreatedAt),
      type: CATEGORIES_DICTIONARY[transaction.Type],
      description: transaction.Description || "",
      participant: transaction.Participant || "",
      movement: MOVEMENT_DICTIONARY[transaction.Movement],
      value: formatCurrency(
        transaction.Movement === "OUT"
          ? transaction.Value * -1
          : transaction.Value,
      ),
    });

    return acc;
  }, []);
};
