import { PayTransferIcon } from "@/assets";
import { TransactionItemV2 as Item } from "@/types";
import { formatCurrency } from "@/utils/format";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { Card, Sheet, SheetTriggerProps } from "@hyperlocal/vital2";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { twMerge } from "tailwind-merge";

export const getIcon = (item: Item) => {
  const iconMap: Partial<Record<Item["type"], IconProps["name"]>> = {
    PAYMENT_SLIP: "GeralBarcode",
    PIX: "MoneyPix",
  };

  if (iconMap[item.type])
    return (
      <Icon name={iconMap[item.type]} fill="currentColor" className="size-6" />
    );

  if (item.movement === "CASH_IN")
    return (
      <Icon
        name="GeralMoneyPayReceived"
        fill="currentColor"
        className="size-6"
      />
    );

  return <PayTransferIcon />;
};

const getTitle = (item: Item): string => {
  if (item.feeType === "BANK_FEE") return "Tarifa TED";

  const titleCashoutMap: Record<Item["type"], string> = {
    P2P: "Transferência Enviada",
    PIX: "Pix Transferência Enviada",
    TED: "Transferência Enviada",
    PAYMENT_SLIP: "Pagamento Efetuado",
  };

  const titleCashinMap: Record<Item["type"], string> = {
    P2P: "Transferência Recebida",
    PIX: "Pix Transferência Recebida",
    TED: "Transferência Recebida",
    PAYMENT_SLIP: "Estorno Recebido",
  };

  return item.movement === "CASH_IN"
    ? titleCashinMap[item.type]
    : titleCashoutMap[item.type];
};

export const TransactionItemV2 = ({ item }: { item: Item }) => {
  const { movement, value, id, transactionDate } = item;

  const isCashIn = movement === "CASH_IN";

  const icon = getIcon(item);

  const transactionDateTime = new Date(transactionDate);

  const transactionTime = transactionDateTime.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const isTedFee = item.feeType === "BANK_FEE";

  return (
    <ReceiptDrawer
      receiptId={id}
      onClick={(e) => {
        if (isTedFee) return e.preventDefault();
      }}
    >
      <li className="w-full">
        <Card
          className={twMerge(
            "statements-list-css-wildcard flex w-full items-center border-neutral-light !px-6 !py-3 mobile:!w-auto mobile:!px-4",
            isTedFee && "cursor-not-allowed",
          )}
        >
          <div
            data-testId="icon-container"
            className={twMerge(
              "h-fit w-fit rounded-circle bg-neutral-lighter p-[8px] text-neutral-black mobile:shrink-0 mobile:p-1",
              isCashIn && "bg-status-success-light text-status-success-dark",
            )}
          >
            {icon}
          </div>
          <div className="statement-text-wildcard ml-3 flex w-full max-w-[165px] flex-col items-start">
            <span className="overflow-hidden text-ellipsis whitespace-nowrap text-left text-x2s/md text-neutral-darkest mobile:w-full mobile:text-x3s">
              {getTitle(item)}
            </span>
            {item.participantName && !isTedFee && (
              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-left font-base text-xs/normal text-neutral-dark mobile:w-full mobile:max-w-[150px] mobile:text-x2s">
                {item.participantName}
              </span>
            )}
          </div>
          <div className="ml-auto flex flex-col text-right">
            <span className="text-right text-x2s/md text-neutral-dark mobile:!text-x4s">
              {transactionTime}
            </span>
            <span
              className={twMerge(
                "teste w-max text-xs/md mobile:w-fit",
                isCashIn ? "text-status-success-dark" : "text-neutral-darker",
              )}
            >
              {formatCurrency(value)}
            </span>
          </div>
        </Card>
      </li>
    </ReceiptDrawer>
  );
};

interface ReceiptDrawerProps extends SheetTriggerProps {
  receiptId: string;
}

const ReceiptDrawer = ({ receiptId, ...rest }: ReceiptDrawerProps) => {
  return (
    <Sheet.Root>
      <Sheet.Trigger {...rest} className="flex w-full" />
      <Sheet.Content className="!p-0">
        <Parcel
          config={() => System.import("@hyperlocal/banking-receipt")}
          mountParcel={mountRootParcel}
          receiptId={receiptId}
          receiptOrigin="statements"
        />
      </Sheet.Content>
    </Sheet.Root>
  );
};
